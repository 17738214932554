<template>
    <div class="mod-user">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item style="margin: 20px 20px 20px 20px">
                <el-button type="primary" @click="getDataList()" style="border-radius: 4px">刷新</el-button>
            </el-form-item>
        </el-form>
        <div style="margin: 0px 20px 20px 20px">
            <el-table
                :data="dataList"
                border
                v-loading="dataListLoading"
                :row-key='getRowKeys'
                style="width: 100%;">
                <!--<el-table-column
                    type="selection"
                    header-align="center"
                    align="center"
                    width="50">
                </el-table-column>-->
                <el-table-column
                    prop="name"
                    header-align="center"
                    align="center"
                    label="流程名称">
                </el-table-column>
                <el-table-column
                    prop="key"
                    header-align="center"
                    align="center"
                    label="流程key">
                </el-table-column>
                <el-table-column
                    prop="version"
                    header-align="center"
                    align="center"
                    label="版本号">
                </el-table-column>
                <el-table-column
                    prop="lastUpdated"
                    header-align="center"
                    align="center"
                    label="修改时间">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                    <template slot-scope="scope">
                        <el-tooltip content="流程部署" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_detail"
                                @click="flowDeploy(scope.row.id)"
                                type="text"
                            ></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 弹窗, 新增 / 修改 -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            getRowKeys(row) {
                return row.id;
            },
            dataForm: {
                key: ''
            },
            orgUserId: '',
            dataList: [],
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false,
            dialogVisible: false,
            dialogModel: false,
            formItem: [],
            theFromClass: {},
            adjustableHeight: '100px',
            formTemplate: '',
            modelKey: ''
        };
    },
    created() {
        this.getDataList();
    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.$client.getRawFlowList().then((res) => {
                if (res) {
                    this.dataList = res.data;
                } else {
                    this.dataList = [];
                }
            });
        },
        //流程部署
        flowDeploy(id) {
            if (!id) {
                return;
            }
            this.$confirm('部署后新发起业务将按该流程启动，确定操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.deployRawFlow(id).then(() => {
                    this.$notify({
                        title: '成功',
                        message: '流程部署成功',
                        type: 'success'
                    });
                });
            });
        },
        preValidate(isSingle) {
            if (this.dataListSelections == null || this.dataListSelections.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.dataListSelections.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
<style>

</style>
